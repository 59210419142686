import '../styles/page-home.scss';
import Swiper from '../js/vendor/swiper/swiper.min';
//Service package slider
let packageSwiper = new Swiper('.package-slider', {
	slidesPerView: 4,
	spaceBetween: 15,
	keyboard: true,
	allowTouchMove: true,
	mousewheel: false,
	simulateTouch:true,
	observer: true,
	observeParents: true,
	passiveListeners: true,
	breakpoints: {
		1200: {
			slidesPerView: 4,
			spaceBetween: 15,
			keyboard: true,
			allowTouchMove: true,
			mousewheel: false,
			simulateTouch:true,
		},
		1024: {
			slidesPerView: 2.6,
			spaceBetween: 15,
			slidesOffsetBefore:25,
			keyboard: true,
			allowTouchMove: true,
			mousewheel: false,
			simulateTouch:true,
		},
		719: {
			slidesPerView: 1.5,
			spaceBetween: 15,
			slidesOffsetBefore:25,
			slidesOffsetAfter:25,
			keyboard: true,
			allowTouchMove: true,
			mousewheel: false,
			simulateTouch:true,
		},
		576: {
			slidesPerView: 1.25,
			spaceBetween: 15,
			keyboard: true,
			allowTouchMove: true,
			mousewheel: false,
			simulateTouch:true
		}
	},
	pagination: {
		el: ".package-slider-pagination",
		clickable: 'true'
	},
	navigation: {
		nextEl: '.swiper-button-next-package',
		prevEl: '.swiper-button-prev-package',
	}
});

let helpSwiper = new Swiper('.help-section__container', {
	updateOnWindowResize: true,
	slidesPerView: 3,
	spaceBetween:15,
	grabCursor: true,
	keyboard: false,
	allowTouchMove: false,
	mousewheel: false,
	simulateTouch:false,
	observer: true,
	observeParents: true,
	passiveListeners: true,
	breakpoints: {
		1200: {
			slidesPerView: 3,
			grabCursor: false,
			keyboard: false,
			allowTouchMove: false,
			mousewheel: false,
			simulateTouch:false,
		},
		719: {
			slidesPerView: 2.3,
			grabCursor: false,
			keyboard: false,
			allowTouchMove: false,
			mousewheel: false,
			simulateTouch:false,
			centeredSlides:false,
			navigation: {
				nextEl: '.swiper-button-next-help',
				prevEl: '.swiper-button-prev-help',
			}
		},
		576: {
			slidesPerView: 1.3,
			slidesOffsetBefore:10,
			slidesOffsetAfter:10,
			grabCursor: true,
			keyboard: true,
			allowTouchMove: true,
			mousewheel: true,
			simulateTouch:true,
			navigation: {
				nextEl: '.swiper-button-next-help',
				prevEl: '.swiper-button-prev-help',
			},
			pagination: {
				el: ".help-slider-pagination",
				clickable: 'true'
			}
		}
	}
});